import styled from 'styled-components'
import { colors } from '../../styles/colors'

export const Weather = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 125px;
    background-color: ${colors.secondary};
    color: ${colors.white};
`

export const WeatherMap = styled.iframe`
    overflow: hidden;
    border: none;
    width: 550px;
    height: 512px;
    transform: scale(1.5);
    transform-origin: top left;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
`
export const WeatherData = styled.div`
    position: absolute;
    top: 100px;
    left: 1050px;
    display: grid;
    text-align: center;
`

export const WeatherTemperature = styled.div`
    font-weight: 700;
    font-size: 200px;
    line-height: 1.2;
    letter-spacing: -0.05em;
    border-bottom: 4px solid ${colors.white};
    width: 100%;
    grid-column: 1 / span 2;
    grid-row: 1;
    margin-bottom: 20px;
    padding-left: 20px;
`
export const WeatherPercipitation = styled.div`
    font-size: 50px;
    letter-spacing: -0.05em;
    grid-column: 1;
    grid-row: 2;
    border-right: 2px solid ${colors.white};
    padding-right: 15px;
`
export const WeatherWind = styled.div`
    font-size: 50px;
    grid-column: 2;
    grid-row: 2;
    border-left: 2px solid ${colors.white};
    padding-left: 15px;
`
export const WeatherImage = styled.div`
    position: absolute;
    top: 100px;
    right: 115px;
`

export const WeatherDescription = styled.div`
    font-size: 50px;
    font-weight: 700;
    position: absolute;
    top: 550px;
    left: 1050px;

    span {
        display: block;
        font-size: 25px;
        font-weight: 400;
    }
`
