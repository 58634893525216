import { motion } from 'framer-motion'
import React, { useEffect } from 'react'

import config from '../../Config'

export const SimpleImage = ({
    image,
    onFinish,
    timing,
    slideId
}: {
    image: string
    timing?: number | null
    onFinish: () => void
    slideId: number
}) => {
    useEffect(() => {
        let timerId: NodeJS.Timeout | null
        if (timing) {
            timerId = setTimeout(onFinish, timing * 1000)
        }
        return () => {
            timerId && clearTimeout(timerId)
        }
    })

    return (
        <div
            style={{
                width: '100vw',
                height: '100vh',
                backgroundSize: ' cover',
                backgroundPosition: 'center',
                backgroundImage: 'url(' + config.imageUrl + image + ')'
            }}
        ></div>
        // <motion.div
        //     key={'slide_' + slideId}
        //     transition={{
        //         duration: 1
        //     }}
        //     initial={{ opacity: 0 }}
        //     animate={{ opacity: 1 }}
        //     exit={{ opacity: 0 }}
        // style={{
        //     width: '100vw',
        //     height: '100vh',
        //     backgroundSize: ' cover',
        //     backgroundPosition: 'center',
        //     backgroundImage: 'url(' + config.imageUrl + image + ')'
        // }}
        // ></motion.div>
    )
}
