import styled from 'styled-components'
import { colors } from '../../styles/colors'

export const NewsTicker = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background: ${colors.primary};
    border-top: 2px solid ${colors.white};
`

export const TickerItems = styled.div`
    display: flex;
`

export const TickerItem = styled.div`
    display: flex;

    img {
        height: 75px;
    }
    strong {
        color: ${colors.white};
        padding: 18px 80px 20px 30px;
        font-size: 25px;
        white-space: nowrap;
    }
`
