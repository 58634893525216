import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

import { ApiCall, getBranches, getPresentations, IGetPresentationsProps } from '../../Api'
import config from '../../Config'

export const BranchSelect = () => {
    const hasLocalStorage = typeof localStorage === 'object'

    const history = useHistory()

    const [branchId, setBranchId] = useState<number>(0)
    const [presentationId, setPresentationId] = useState<number>(0)

    const { data: branchData, status: branchStatus } = useQuery([ApiCall.BRANCHES], getBranches)

    const { data: presentationData, status: presentationStatus } = useQuery(
        [ApiCall.PRESENTATIONS, { branchId }],
        (_, props: IGetPresentationsProps) => getPresentations(props)
    )

    useEffect(() => {
        let autoRedirect: any = null
        if (hasLocalStorage) {
            const branch = Number(localStorage.getItem(`branchId_${config.version}`))

            if (branch !== null && !isNaN(branch)) {
                setBranchId(branch)
                const presentation = Number(localStorage.getItem(`presentationId_${config.version}`))

                if (presentation !== null && !isNaN(presentation)) {
                    setPresentationId(presentation)
                    const url = localStorage.getItem(`url_${config.version}`)

                    if (url !== null) {
                        autoRedirect = setTimeout(() => {
                            history.push(url)
                        }, 20 * 1000)
                    }
                }
            }
        }

        if (autoRedirect !== null) {
            return () => {
                clearTimeout(autoRedirect)
            }
        }
    }, [hasLocalStorage, history])

    if (branchStatus !== 'success') return null
    if (!branchData) return null

    const goToUrl = ({
        url,
        branchId,
        presentationId
    }: {
        url: string
        branchId: number
        presentationId: number
    }) => {
        if (hasLocalStorage) {
            localStorage.setItem(`url_${config.version}`, url)
            localStorage.setItem(`branchId_${config.version}`, String(branchId))
            localStorage.setItem(`presentationId_${config.version}`, String(presentationId))
        }

        history.push(url)
    }

    return (
        <>
            <select
                style={{
                    position: 'absolute',
                    left: '50%',
                    top: '47%',
                    transform: 'translate(-50%, -50%)',
                    fontFamily: 'Arial, Helvetica, sans-serif',
                    padding: '0.5rem',
                    border: '1px solid grey',
                    borderRadius: '0.25rem'
                }}
                value={branchId}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setBranchId(Number(e.target.value) || 0)
                }}
            >
                <option>Selecteer een vestiging</option>
                {branchData.branches.sort().map((b) => (
                    <option key={b.id} value={b.id}>
                        {b.title}
                    </option>
                ))}
            </select>

            {presentationStatus === 'success' &&
                presentationData &&
                (presentationData?.presentations.length || 0) > 0 && (
                    <select
                        style={{
                            position: 'absolute',
                            left: '50%',
                            top: '53%',
                            transform: 'translate(-50%, -50%)',
                            fontFamily: 'Arial, Helvetica, sans-serif',
                            padding: '0.5rem',
                            border: '1px solid grey',
                            borderRadius: '0.25rem'
                        }}
                        value={presentationId}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            // state is never processed because of the instant redirect
                            setPresentationId(Number(e.target.value) || 0)

                            if (branchId && Number(e.target.value)) {
                                goToUrl({
                                    url: `${
                                        branchData.branches.find((b) => b.id === branchId)!.url
                                    }?presentationId=${e.target.value}`,
                                    branchId,
                                    presentationId: Number(e.target.value)
                                })
                            }
                        }}
                    >
                        <option>Selecteer een presentatie</option>
                        {presentationData.presentations.sort().map((p) => (
                            <option key={p.id} value={p.id}>
                                {p.title}
                            </option>
                        ))}
                    </select>
                )}

            {branchId > 0 &&
                presentationStatus === 'success' &&
                !presentationData?.presentations.length && (
                    <div
                        style={{
                            position: 'absolute',
                            left: '50%',
                            top: '53%',
                            transform: 'translate(-50%, -50%)',
                            fontFamily: 'Arial, Helvetica, sans-serif'
                        }}
                    >
                        Geen presentaties
                    </div>
                )}
        </>
    )
}
