import { motion } from 'framer-motion'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { ApiCall, getWeather } from '../../Api'
import { Coord, getClosestCoord } from '../../Utils/sortByDistance'
import {
    Weather,
    WeatherDescription,
    WeatherTemperature,
    WeatherImage,
    WeatherPercipitation,
    WeatherWind,
    WeatherMap,
    WeatherData
} from './styled'

export const WeatherSlide = ({
    coord,
    region,
    onFinish,
    timing,
    slideId
}: {
    coord: Coord
    region: string
    timing?: number | null
    onFinish: () => void
    slideId: number
}) => {
    useEffect(() => {
        let timerId: NodeJS.Timeout | null
        if (timing) {
            timerId = setTimeout(onFinish, timing * 1000)
        }
        return () => {
            timerId && clearTimeout(timerId)
        }
    })

    const { data, status } = useQuery([ApiCall.WEATHER], getWeather)
    if (status !== 'success') return null
    if (!data) return null

    // Meetstation op de noordzee meet niet alles..
    const stationMeasurements = data.actual.stationmeasurements.filter(
        (m) =>
            m.temperature !== undefined &&
            m.precipitation !== undefined &&
            m.winddirection !== undefined
    )

    const closest = getClosestCoord({
        coord,
        coords: stationMeasurements.map((m) => ({ lat: m.lat, lng: m.lon }))
    })
    if (!closest) return null

    const closestData = stationMeasurements.find(
        (m) => m.lat === closest.lat && m.lon === closest.lng
    )
    if (!closestData) return null

    return (
        <div
            style={{
                width: '100vw',
                height: '100vh',
                background: 'grey'
            }}
        >
            {/* 
      // <motion.div
        //     key={'slide_' + slideId}
        //     transition={{
        //         duration: 1
        //     }}
        //     initial={{ opacity: 0 }}
        //     animate={{ opacity: 1 }}
        //     exit={{ opacity: 0 }}
        //     style={{
        //         width: '100vw',
        //         height: '100vh',
        //         background: 'grey'
        //     }}
        // >
         */}

            <Weather>
                <WeatherMap
                    src={
                        'https://gadgets.buienradar.nl/gadget/zoommap/?lat=' +
                        closestData.lat +
                        '&lng=' +
                        closestData.lon +
                        '&overname=2&zoom=8&size=3&voor=0'
                    }
                ></WeatherMap>
                <WeatherData>
                    <WeatherTemperature>{closestData.temperature}°</WeatherTemperature>
                    <WeatherPercipitation>{closestData.precipitation} mm</WeatherPercipitation>
                    <WeatherWind>{closestData.winddirection}</WeatherWind>
                </WeatherData>
                <WeatherImage>
                    <img src={closestData.iconurl.replace('30x30', '300x300')} alt="" />
                </WeatherImage>
                <WeatherDescription>
                    <span>Actuele weersverwachting in de regio {region || closestData.regio}:</span>
                    {closestData.weatherdescription}
                </WeatherDescription>
            </Weather>
        </div>
        // </motion.div>
    )
}
