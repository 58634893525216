import './styles/base.scss'

import React from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'

import { BranchSelect } from './Components/BranchSelect'
import { SlideShow } from './Components/SlideShow'

const App = () => {
    return (
        <Router>
            <Switch>
                <Route path="/" exact>
                    <BranchSelect />
                </Route>
                <Route path="/">
                    <SlideShow />
                </Route>
            </Switch>
        </Router>
    )
}

export default App
