export type Coord = { lat: number; lng: number }

export const getClosestCoord = ({ coord, coords }: { coord: Coord; coords: Coord[] }) => {
    return coords.sort((a, b) => distance(a, coord) - distance(b, coord)).find((_) => true)
}

const distance = (a: Coord, b: Coord) => {
    const { lat: lat1, lng: lon1 } = a
    const { lat: lat2, lng: lon2 } = b

    const $theta = lon1 - lon2

    let dist =
        Math.sin(deg2rad(lat1)) * Math.sin(deg2rad(lat2)) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.cos(deg2rad($theta))

    dist = Math.acos(dist)
    dist = rad2deg(dist)

    return dist * 60 * 1.1515
}

const deg2rad = (angleInDegrees: number) => angleInDegrees * (Math.PI / 180)
const rad2deg = (angleInRadians: number) => angleInRadians * (180 / Math.PI)
