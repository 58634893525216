import axios from 'axios'

import config from '../Config'

import RSSParser from 'rss-parser'

type Branches = {
    branches: { id: number; title: string; url: string }[]
}

export const getBranches = async () => {
    const getBranchesResponse = await axios.get<Branches>(`${config.backendUrl}/branch-select/`)
    if (getBranchesResponse.status !== 200 || !getBranchesResponse.data) {
        throw new Error('No data')
    }

    return getBranchesResponse.data
}

type Presentations = {
    presentations: { id: number; title: string; url: string }[]
}

export interface IGetPresentationsProps {
    branchId?: number
}

export const getPresentations = async ({ branchId }: IGetPresentationsProps) => {
    const getPresentationsResponse = await axios.get<Presentations>(
        `${config.backendUrl}/presentation-select/?branchId=${branchId}`
    )
    if (getPresentationsResponse.status !== 200 || !getPresentationsResponse.data) {
        throw new Error('No data')
    }

    return getPresentationsResponse.data
}

export interface IGetSlidesProps {
    url: string
    presentationId: number
}

type Slides = {
    branch: { latitude: string; longitude: string; region: string }
    slides: (
        | { key: number; title: string; template: 'image_slide'; timing: number; image: string }
        | {
              key: number
              title: string
              template: 'video_slide'
              timing: number
              youtube_code: string
          }
        | { key: number; title: string; template: 'weather_slide'; timing: number }
    )[]
}

export const getSlides = async ({ url, presentationId }: IGetSlidesProps) => {
    const getSlidesResponse = await axios.get<Slides>(
        `${config.backendUrl}${url}/?presentationId=${presentationId}`
    )

    if (getSlidesResponse.status !== 200 || !getSlidesResponse.data) {
        throw new Error('No data')
    }

    return getSlidesResponse.data
}

type RSSNuNL = {
    items: [
        {
            creator: string
            rights: string
            title: string
            link: string
            pubDate: string
            enclosure: {
                length: string
                type: string
                url: string
            }
            'dc:creator': string
            content: string
            contentSnippet: string
            guid: string
            categories: string[]
            isoDate: string
        }
    ]
    feedUrl: string
    title: string
    description: string
    link: string
    language: string
    copyright: string
    lastBuildDate: string
    ttl: string
}

export const getNews = async () => {
    const getNewsResponse = await axios.get(`${config.backendUrl}/feed_nu.nl.php`)

    if (getNewsResponse.status !== 200 || !getNewsResponse.data) {
        throw new Error('No data')
    }

    return await new RSSParser<RSSNuNL>().parseString(getNewsResponse.data)
}

type Weather = {
    $id: string
    buienradar: {
        $id: string
        copyright: string
        terms: string
    }
    actual: {
        $id: string
        actualradarurl: string
        sunrise: string
        sunset: string
        stationmeasurements: {
            $id: string
            stationid: number
            stationname: string
            lat: number
            lon: number
            regio: string
            timestamp: string
            weatherdescription: string
            iconurl: string
            graphUrl: string
            winddirection: string
            temperature: number
            groundtemperature: number
            feeltemperature: number
            windgusts: number
            windspeed: number
            windspeedBft: number
            humidity: number
            precipitation: number
            sunpower: number
            rainFallLast24Hour: number
            rainFallLastHour: number
            winddirectiondegrees: number
        }[]
    }
    forecast: {
        $id: string
        weatherreport: {
            $id: string
            published: string
            title: string
            summary: string
            text: string
            author: string
            authorbio: string
        }
        shortterm: {
            $id: string
            startdate: string
            enddate: string
            forecast: string
        }
        longterm: {
            $id: string
            startdate: string
            enddate: string
            forecast: string
        }
        fivedayforecast: {
            $id: string
            day: string
            mintemperature: string
            maxtemperature: string
            mintemperatureMax: number
            mintemperatureMin: number
            maxtemperatureMax: number
            maxtemperatureMin: number
            rainChance: number
            sunChance: number
            windDirection: string
            wind: number
            mmRainMin: number
            mmRainMax: number
            weatherdescription: string
            iconurl: string
        }[]
    }
}

export const getWeather = async () => {
    const getNewsResponse = await axios.get<Weather>(`https://data.buienradar.nl/2.0/feed/json`)

    if (getNewsResponse.status !== 200 || !getNewsResponse.data) {
        throw new Error('No data')
    }

    return getNewsResponse.data
}
