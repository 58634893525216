import { motion } from 'framer-motion'
import React, { useEffect } from 'react'
import YouTube from 'react-youtube'

export const SimpleVideo = (props: {
    youtube_code: string
    onFinish: () => void
    timing?: number | null
    slideId: number
}) => {
    const { youtube_code, timing, onFinish, slideId } = props

    const timerIds: NodeJS.Timeout[] = []

    useEffect(() => {
        if (timing) {
            timerIds.push(setTimeout(onFinish, timing * 1000))
        }

        return () => {
            timerIds.forEach((timerId) => clearTimeout(timerId))
        }
    })

    return (
        // <motion.div
        //     key={'slide_' + slideId}
        //     transition={{
        //         duration: 0
        //     }}
        //     initial={{ opacity: 1 }}
        //     animate={{ opacity: 1 }}
        //     exit={{ opacity: 1 }}
        // >
        <YouTube
            videoId={youtube_code}
            id={youtube_code}
            className={'player'}
            containerClassName={'video-slide'}
            opts={{
                width: '100%',
                height: '100%',
                playerVars: {
                    modestbranding: 1,
                    showinfo: 0,
                    rel: 0,
                    playsinline: 1,
                    autoplay: 1
                }
            }}
            onReady={(event) => {
                event.target.mute()
                event.target.playVideo()
            }}
            onPlay={(event) => {
                timerIds.push(setTimeout(onFinish, event.target.getDuration() * 1000 - 1000))
            }}
            onEnd={onFinish}
            onError={onFinish}
        />
        // </motion.div>
    )
}
