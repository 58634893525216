const getConfig = () => {
    const version = 1
    if (process.env.NODE_ENV === 'development') {
        return {
            backendUrl: 'http://bpg-narrowcasting.stan.o.xotap.nl/',
            imageUrl: 'http://bpg-narrowcasting.stan.o.xotap.nl/'
        }
    } else {
        return {
            backendUrl: window.location.protocol + '//' + window.location.host,
            imageUrl: window.location.protocol + '//' + window.location.host,
            version
        }
    }
}

const config = getConfig()

export type IConfig = typeof config
export default config as IConfig
